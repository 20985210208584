import {IRoutesPermissions} from "./permission.interface";

export const routesPermissions:IRoutesPermissions = {
    'dashboard':{
        url: '/dashboard',
        permissions: [{ module:'core:user',key:'can_view_dashboard'}], //remove permissions when we have completed KPI for the dashboard
    },
    'welcome':{
        url: '/welcome',
        permissions: [],
    },
    'leadSourceReports':{
        url: '/reports/contacts/lead-source',
        permissions: [
            { module:'hubspot:contact:report',key:'lead_source'},{ module:'hubspot:contact',key:'unrestricted'},{ module:'hubspot:contact:report',key:'all'}
        ]
    },
    'compareLeadSourceReports':{
        url: '/reports/contacts/compare-lead-source',
        permissions: [
            { module:'hubspot:contact:report',key:'compare_lead_source'},{ module:'hubspot:contact',key:'unrestricted'},{ module:'hubspot:contact:report',key:'all'}
        ]
    },
    'compareFormConversionReports':{
        url: '/reports/contacts/compare-form-conversion',
        permissions: [
            { module:'hubspot:contact:report',key:'compare_form_conversions'}
        ]
    },
    'salesReports':{
        url: '/reports/contacts/sales',
        permissions: [
            { module:'hubspot:contact:report',key:'sales'},
            { module:'hubspot:contact',key:'unrestricted'},
            { module:'hubspot:contact:report',key:'all'}

        ]
    },
    'goalsReports':{
        url: '/reports/contacts/goals',
        permissions: [
            { module:'hubspot:franchisee',key:'manage'},
            { module:'hubspot:franchisee',key:'view'}
        ]
    },
    'googleAdsReports':{
        url: '/reports/ads/google',
        permissions: [
            { module:'hubspot:franchisee',key:'manage'},
            { module:'hubspot:franchisee',key:'list'},
            { module:'hubspot:franchisee',key:'view'},
        ]
    },
    'surveyReports':{
        url: '/reports/customer-experience/survey',
        permissions:[{ module:'hubspot:franchisee',key:'unrestricted'},{ module:'birdeye:survey',key:'view'},{ module:'birdeye:survey',key:'list'}]
    },
    'franchisees':{
        url: '/entities/hubspot/franchisees',
        permissions: [{ module:'hubspot:franchisee',key:'manage'},{ module:'hubspot:franchisee',key:'view'}]
    },
    'territories':{
        url: '/entities/hubspot/territories',
        permissions: [
            { module:'hubspot:franchisee',key:'manage'},{ module:'hubspot:territory',key:'manage'}
        ]
    },
    'lists':{
        url: '/entities/hubspot/lists',
        permissions: [{ module:'hubspot:list',key:'manage'},{ module:'hubspot:list',key:'view'}]
    },
    'deals':{
        url: '/entities/hubspot/deals',
        permissions:[ { module:'hubspot:deal',key:'manage'}]
    },
    'contacts':{
        url: '/entities/hubspot/contacts',
        permissions: [{ module:'hubspot:contact',key:'manage'},{ module:'hubspot:contact',key:'view'},{ module:'hubspot:contact',key:'list'}]
    },
    'lifeCycleStages':{
        url: '/entities/hubspot/lifecyclestages',
        permissions: [{ module:'hubspot:lifecyclestage',key:'manage'}]
    },
    'reviews':{
        url: '/entities/customer-experience/reviews',
        permissions: [{ module:'birdeye:review',key:'view'},{ module:'birdeye:review',key:'list'},{ module:'hubspot:franchisee',key:'unrestricted'}]
    },
    'surveys':{
        url: '/entities/customer-experience/surveys',
        permissions: [{ module:'birdeye:survey',key:'unrestricted'}]
    },
    'users':{
        url: '/admin/users',
        permissions: [{ module:'core:user',key:'manage'},{ module:'core:user',key:'moderate'},{ module:'core:user',key:'list'}]
    },
    'roles':{
        url: '/admin/roles',
        permissions: [{ module:'core:role',key:'manage'}]
    },
    'permissions':{
        url: '/admin/permissions',
        permissions: [ { module:'core:permission', key:'manage'} ],
    },
    'franchiseesGoals':{
        url: '/entities/hubspot/franchisees-goals',
        permissions: [{ module:'core:franchisee',key:'manage'},{ module:'hubspot:franchisee',key:'franchisee_goal'}]
    },
    'feedback':{
        url: '/feedbacks',
        permissions: [{ module:'core:feedback',key:'view'},{ module:'core:feedback',key:'list'}]
    },

}